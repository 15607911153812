// @flow
type StateType = {
  showFilters: boolean,
  page: number,
  loading: boolean,
  isSearchingAndIsNewSearch: boolean,
  sortDir?: string,
  sortBy?: string
};

const initialState = {
  showFilters: true,
  page: 1,
  loading: false,
  isSearchingAndIsNewSearch: false
};

function calculateNewSortBy(state, action) {
  return action.item;
}

const sortAscOnlyColumns = ['compare', 'shape'];

function calculateNewSortDir(state, action, newSortBy) {
  let sortDir = 'asc';

  if (
    sortAscOnlyColumns.indexOf(action.item) === -1 &&
    state.sortBy === action.item &&
    state.sortDir === 'asc'
  ) {
    sortDir = 'desc';
  }

  return sortDir;
}

export default function diamondSearch(
  state: StateType = initialState,
  action: any,
) {
  switch (action.type) {
    // a toggle action checks to see if an item exists; if it does, it's removed, otherwise it's added
    case 'UPDATE_DIAMOND_SEARCH_PAGE':
      return {
        ...state,
        page: action.item
      };
    case 'TOGGLE_FILTERS':
      return {
        ...state,
        showFilters: !state.showFilters
      };
    case 'FETCH_DIAMONDS_STARTED':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_DIAMONDS_SUCCESS':
      return {
        ...state,
        loading: false
      };
    case 'UPDATE_DIAMOND_SEARCH_COLUMN':
      return {
        ...initialState,
        ...state,
        sortBy: calculateNewSortBy(state, action),
        sortDir: calculateNewSortDir(state, action),
      };
    case 'NEW_DIAMOND_SEARCH_STARTED':
      return {...state, isSearchingAndIsNewSearch: true};
    case 'NEW_DIAMOND_SEARCH_FINISHED':
      return {...state, isSearchingAndIsNewSearch: false};
    default:
      return state;
  }
}
