// @flow
import qs from 'qs';

import type { ObjectType } from '@types/misc';

export function detailPath(
  diamond: { list_service_diamond_id: number },
  params: ObjectType = {}
) {
  const queryString: string = qs.stringify(params);
  const id = diamond.list_service_diamond_id;

  if (!id) return '';

  if (queryString.length > 0) {
    return `/diamonds/detail/${id}?${queryString}`;
  } else {
    return `/diamonds/detail/${id}`;
  }
}

export function looseDetailPath(
  diamond: { list_service_diamond_id: number },
  params: ObjectType = {}
) {
  const queryString: string = qs.stringify(params);
  const id = diamond.list_service_diamond_id;

  if (!id) return '';

  if (queryString.length > 0) {
    return `/loose-diamonds/detail/${id}?${queryString}`;
  } else {
    return `/loose-diamonds/detail/${id}`;
  }
}

export function searchPath(params: Object = {}) {
  const queryString: string = qs.stringify(params, { arrayFormat: 'brackets' });
  const path = '/diamonds/search';

  if (queryString.length > 0) {
    return `${path}?${queryString}`;
  } else {
    return path;
  }
}

export function looseSearchPath(params: Object = {}) {
  const queryString: string = qs.stringify(params, { arrayFormat: 'brackets' });
  const path = '/loose-diamonds/search';

  if (queryString.length > 0) {
    return `${path}?${queryString}`;
  } else {
    return path;
  }
}

export function apiIndex(params: Object = {}) {
  const queryString: string = qs.stringify(params, { arrayFormat: 'brackets' });
  const path = '/api/diamonds.json';

  if (queryString.length > 0) {
    return `${path}?${queryString}`;
  } else {
    return path;
  }
}

export function apiDyor(id: string) {
  if (!id) throw new Error('ID is required');

  return `/api/diamonds/${id}/dyor.json`;
}

export function apiDetail(id: string) {
  if (!id) throw new Error('ID is required');

  return `/api/diamonds/${id}.json`;
}

export function savePath(id: number, params: Object = {}) {
  const queryString: string = qs.stringify(params, { arrayFormat: 'brackets' });
  const path = `/api/diamonds/${id}/save.json`;

  if (queryString.length > 0) {
    return `${path}?${queryString}`;
  } else {
    return path;
  }
}

export function sortComparison(params: Object = {}): string {
  const queryString: string = qs.stringify(params, { arrayFormat: 'brackets' });
  const path = '/api/diamonds/sort_comparison.json';

  if (queryString.length > 0) {
    return `${path}?${queryString}`;
  } else {
    return path;
  }
}

export const getSavedDiamonds = '/api/diamonds/saved.json';
