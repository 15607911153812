// @flow
import { fork, all } from 'redux-saga/effects';

import diamondsSaga from '@src/sagas/diamonds';
import ringBuilderSaga from '@src/sagas/ringBuilder';
import cartSaga from '@src/sagas/cart';
import diamondSearchSaga from '@src/sagas/diamondSearch';
import settingsSaga from '@src/sagas/settings';
import bandsSaga from '@src/sagas/bands';

export default function* rootSaga(): Generator<any, any, any> {
  yield all([
    fork(diamondsSaga),
    fork(ringBuilderSaga),
    fork(cartSaga),
    fork(diamondSearchSaga),
    fork(settingsSaga),
    fork(bandsSaga)
  ]);
}
