// @flow
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import AxiosInstance from '@src/utils/axiosInstance';
import { handleError } from '@src/utils/errors';

import * as dyorRoutes from '@src/app_routes/ringBuilder';
import * as settingRoutes from '@src/app_routes/settings';
import * as diamondRoutes from '@src/app_routes/diamonds';

const axiosInstance = AxiosInstance();

const getDyorSettingSku = state => state.ringBuilder.setting.sku;
const getDyorDiamondId = state => state.ringBuilder.diamond.id;

export default function* ringBuilderIndex(): Generator<any, any, any> {
  yield all([
    takeLatest('CACHE_DYOR_SETTING', cacheDyorSetting),
    takeLatest('CLEAR_DYOR_SETTING', clearDyorSetting),
    takeLatest('CACHE_DYOR_DIAMOND', cacheDyorDiamond),
    takeLatest('CLEAR_DYOR_DIAMOND', clearDyorDiamond),
    takeLatest(
      'SELECT_DYOR_SETTING_SKU_AND_DIAMOND_ID',
      selectDyorSettingSkuAndDiamondId
    )
  ]);
}

export function* cacheDyorSetting(): Generator<any, any, any> {
  try {
    const sku = yield select(getDyorSettingSku);

    const res = yield call(axiosInstance.get, settingRoutes.apiDyor(sku));
    yield put.resolve({ type: 'UPDATE_DYOR_SETTING_DATA', payload: res.data });
  } catch (e) {
    handleError(e);
  }
}

export function* clearDyorSetting({callback}): Generator<any, any, any> {
  try {
    const res = yield call(
      axiosInstance.delete,
      dyorRoutes.apiClearSettingPath
    );
    if (res.status === 200) yield put({ type: 'PROCESS_CLEARED_DYOR_SETTING' });

    if (callback) {
      yield call(callback);
    }
  } catch (e) {
    handleError(e);
  }
}

export function* cacheDyorDiamond(): Generator<any, any, any> {
  try {
    const id = yield select(getDyorDiamondId);
    const res = yield call(axiosInstance.get, diamondRoutes.apiDyor(id));
    yield put({ type: 'UPDATE_DYOR_DIAMOND_DATA', payload: res.data });
  } catch (e) {
    handleError(e);
  }
}

type TClearDyorDiamondProps = {
  callback?: () => void
};

export function* clearDyorDiamond({
  callback
}: TClearDyorDiamondProps): Generator<any, any, any> {
  try {
    const res = yield call(
      axiosInstance.delete,
      dyorRoutes.apiClearDiamondPath
    );
    if (res.status === 200) yield put({ type: 'PROCESS_CLEARED_DYOR_DIAMOND' });

    if (callback) {
      yield call(callback);
    }
  } catch (e) {
    handleError(e);
  }
}

type TSelectDyorSettingSkuAndDiamondIdProps = {
  payload: {
    diamondId?: string,
    settingSku?: string
  },
  onSuccess?: () => void,
  onFailure?: () => void,
  redirectTo?: string,
  allowNextCompatibleSetting?: boolean
};

export function* selectDyorSettingSkuAndDiamondId(
  action: TSelectDyorSettingSkuAndDiamondIdProps
): Generator<any, any, any> {
  yield put.resolve({
    type: 'SELECT_DYOR_SETTING_SKU_AND_DIAMOND_ID_STARTED'
  });

  try {
    const currentSettingSku = yield select(
      state => state.ringBuilder.setting.sku
    );
    const res = yield call(
      axiosInstance.post,
      dyorRoutes.apiSaveDiamondAndSetting,
      {
        diamond_id: action.payload.diamondId,
        setting_sku: action.payload.settingSku,
        allow_next_compatible_setting: action.allowNextCompatibleSetting
      }
    );

    if (res.status === 200) {
      yield put.resolve({
        type: 'UPDATE_DYOR_DIAMOND_ID',
        payload: res.data.diamondId
      });

      if (
        res.data.settingSku &&
        currentSettingSku !== res.data.settingSku
      ) {
        yield put.resolve({
          type: 'RESET_DIAMOND_SEARCH_SHAPES_FOR_NEW_DYOR_SETTING'
        });
      }

      yield put.resolve({
        type: 'UPDATE_DYOR_SETTING_SKU',
        payload: action.payload.settingSku
      });

      yield put.resolve({
        type: 'SELECT_DYOR_SETTING_SKU_AND_DIAMOND_ID_FINISHED'
      });

      if (action.onSuccess) {
        yield call(action.onSuccess);
      }

      yield call(() => {
        if (action.redirectTo && window) {
          if (typeof action.redirectTo === 'function') {
            window.location = action.redirectTo({
              settingSku: res.data.settingSku,
              diamondId: res.data.diamondId
            });
          } else {
            window.location = action.redirectTo;
          }
        }
      });
      return true;
    }
  } catch (e) {
    if (action.onFailure) {
      yield call(action.onFailure);
    }

    yield put({
      type: 'SELECT_DYOR_SETTING_SKU_AND_DIAMOND_ID_FINISHED'
    });

    yield call(() => handleError(e));
  }
}
