// @flow

import type { CartItemType } from '../types/cart';

export type TotalsType = {
  item_total: number,
  sales_tax: number,
  order_total: number,
};

type StateType = {
  items: Array<CartItemType>,
  totals: TotalsType,
};

type ActionType = {
  type: 'CART_UPDATED',
};

const initialState = {
  items: [],
  totals: {
    item_total: 0,
    sales_tax: 0,
    order_total: 0,
  },
};

export default function cart(
  state: StateType = initialState,
  action: ActionType,
) {
  switch (action.type) {
    case 'CART_UPDATED':
      return action.cart;
    default:
      return state;
  }
}
