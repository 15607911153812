// @flow
import type { SettingType } from '@types/setting';

type StateType = {
  allAvailableMatchesCount: number,
  price: Array<number>
};

const defaultState = {
  allAvailableMatchesCount: 0,
  price:[0, 10000]
};

export default function settingSearchExtents (state: StateType = defaultState, action: any) {
  switch (action.type) {
    case 'UPDATE_SETTINGS_EXTENTS':
      const minPrice = Number(action.payload.minPrice);
      let maxPrice = Number(action.payload.maxPrice);

      // nouislider blows up if the min and max are the same value
      if (maxPrice <= minPrice) {
        maxPrice = minPrice + 1;
      }

      return {
        ...state,
        allAvailableMatchesCount: action.payload.allAvailableMatchesCount,
        price: [minPrice, maxPrice]
      };

    default:
      return state;
  }
}
