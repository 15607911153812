export const metalsKeys = [
  ['14K-Yellow-Gold', '14KY'],
  ['14K-White-Gold', '14KW'],
  ['14K-Rose-Gold', '14KR'],
  ['14K-Yellow-White', '14KY_14KW'],
  ['14K-Yellow-Rose', '14KY_14KR'],
  ['14K-White-Yellow', '14KW_14KY'],
  ['14K-White-Rose', '14KW_14KR'],
  ['14K-Rose-Yellow', '14KR_14KY'],
  ['14K-Rose-White', '14KR_14KW'],
  ['18K-Yellow-Gold', '18KY'],
  ['18K-White-Gold', '18KW'],
  ['18K-Rose-Gold', '18KR'],
  ['18K-Yellow-White', '18KY_18KW'],
  ['18K-Yellow-Rose', '18KY_18KR'],
  ['18K-White-Yellow', '18KW_18KY'],
  ['18K-White-Rose', '18KW_18KR'],
  ['18K-Rose-Yellow', '18KR_18KY'],
  ['18K-Rose-White', '18KR_18KW'],
  ['Platinum', 'PLAT'],
  ['Titanium', 'TITANIUM'],
  ['Black-Titanium', 'BLACKTITANIUM'],
  ['Two-Tone', 'Two Tone']
];

const encodedMetals = {};
const decodedMetals = {};

metalsKeys.forEach((pair) => {
  encodedMetals[pair[1]] = pair[0];
  decodedMetals[pair[0]] = pair[1];
});

export const decodeMetalSearchValue = (value) => {
  return decodedMetals[value] || value;
};

export const encodeMetalSearchValue = (value) => {
  return encodedMetals[value] || value;
};
