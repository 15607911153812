// @flow
import type { ObjectType } from '@types/misc';
import type { DyorDiamondType } from '@types/diamond';
import type { DyorSettingType } from '@types/setting';

type StateType = {
  diamond: {
    id: null | string,
    data: DyorDiamondType
  },
  setting: {
    sku: null | string,
    data: DyorSettingType
  },
  firstStep: ?string
};

const initialState = {
  diamond: {},
  setting: {},
  compatible: true,
  firstStep: null
};

export default function ringBuilder(
  state: StateType = initialState,
  action: ObjectType
) {
  switch (action.type) {
    case 'UPDATE_DYOR_SETTING_SKU':
      return {
        ...state,
        setting: {
          ...state.setting,
          sku: action.payload
        }
      };
    case 'UPDATE_DYOR_SETTING_DATA':
      return {
        ...state,
        setting: {
          ...state.setting,
          data: action.payload
        },
        firstStep: state.firstStep || 'setting'
      };
    case 'PROCESS_CLEARED_DYOR_SETTING':
      // If there is a diamond, we want to update the first step to be diamond.
      // Otherwise, we don't want to have a value set.
      return {
        ...state,
        setting: {},
        firstStep: state.diamond.id ? 'diamond' : null
      };
    case 'UPDATE_DYOR_DIAMOND_ID':
      return {
        ...state,
        diamond: {
          ...state.diamond,
          id: action.payload
        }
      };
    case 'UPDATE_DYOR_DIAMOND_DATA':
      return {
        ...state,
        diamond: {
          ...state.diamond,
          data: action.payload
        },
        firstStep: state.firstStep || 'diamond'
      };
    case 'PROCESS_CLEARED_DYOR_DIAMOND':
      // If there is a setting, we want to update the first step to be setting.
      // Otherwise, we don't want to have a value set.
      return {
        ...state,
        diamond: {},
        firstStep: state.setting.sku ? 'setting' : null
      };
    case 'UPDATE_DYOR_COMPATIBILITY':
      return {
        ...state,
        compatibility: action.payload
      };
    case 'SELECT_DYOR_SETTING_SKU_AND_DIAMOND_ID_STARTED':
      return {
        ...state,
        syncingToServer: true
      };
    case 'SELECT_DYOR_SETTING_SKU_AND_DIAMOND_ID_FINISHED':
      return {
        ...state,
        syncingToServer: false
      };
    case 'DYOR_SERVER_PULL_COMPLETE':
      return {
        ...state,
        serverPullComplete: true
      };
    default:
      return state;
  }
}
