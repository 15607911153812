// @flow
// import 'stop-runaway-react-effects/hijack';

import './mobile-nav-bundle';
import './popups-bundle';

import { setStylesTarget } from 'typestyle';

import ShoppingBagLinks from '@components/header/ShoppingBagLinks';
import ServerDataSynchronizer from '@components/synchronizers/ServerDataSynchronizer';

import SharedReduxStore from '@src/store';

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

ReactOnRails.register({
  ShoppingBagLinks,
  ServerDataSynchronizer
});

ReactOnRails.registerStore({
  SharedReduxStore
});

setStylesTarget(document.getElementById('styles-target'));
