import { metalsKeys } from './settings';

const encodedMetals = {};
const decodedMetals = {};

metalsKeys.forEach((pair) => {
  encodedMetals[pair[1]] = pair[0];
  decodedMetals[pair[0]] = pair[1];
});

export const decodeMetalSearchValue = (value) => {
  return decodedMetals[value] || value;
};

export const encodeMetalSearchValue = (value) => {
  return encodedMetals[value] || value;
};
