// @flow
import type { SettingType } from '@types/setting';

type StateType = {
  matches: Array<SettingType>,
  matchCount: number
};

const defaultState = {
  matches: [],
  matchCount: 0
};

export default function settings(state: StateType = defaultState, action: any) {
  switch (action.type) {
    case 'SETTINGS_RECEIVED':
      return {
        ...state,
        matches: [...state.matches, ...action.payload]
      };

    case 'SETTINGS_COUNT_RECEIVED':
      return {
        ...state,
        matchCount: action.payload
      };

    case 'CLEAR_SETTINGS':
      return { ...state, matches: [], matchCount: 0 };

    default:
      return state;
  }
}
