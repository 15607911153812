// @flow
import { Provider, useSelector } from 'react-redux';

import ShoppingBagText from './ShoppingBagText';
import SavedDiamondsText from './SavedDiamondsText';

function ShoppingBagLinks () {
  const savedDiamondCount = useSelector(
    (state) => state.diamondComparison.ids.length
  );
  const savedSettingsCount = useSelector(
    (state) => state.settingFavorites.skus.length
  );
  const bagCount = useSelector((state) => {
    try {
      const dyorDiamonds = state.cart.items.filter((item) => {
        return item.type === 'Diamond' && item.is_dyor;
      });

      // We don't want to show 2 for each dyor combo, so subtract the dyor
      // diamond count to make it only display 1 per dyor combo
      return state.cart.items.length - dyorDiamonds.length;
    } catch (_e) {
      return 0;
    }
  });

  const savedCount = savedDiamondCount + savedSettingsCount;

  return (
    <ul>
      <li>
        <ShoppingBagText count={bagCount} />
      </li>
      <li>
        <SavedDiamondsText count={savedCount} />
      </li>
    </ul>
  );
}

export default () => {
  const store = ReactOnRails.getStore('SharedReduxStore');

  return (
    <Provider store={store}>
      <ShoppingBagLinks />
    </Provider>
  );
};
