// @flow

type TState = {
  collapseStatus: boolean
};

type TAction = {
  type: string,
  status: boolean
};

const defaultState = {
  collapseStatus: false
};

function productCatalogs(state: TState = defaultState, action: TAction) {
  if (action.type === 'UPDATE_MARKETING_TEXT_COLLAPSE_STATUS') {
    return { ...state, collapseStatus: action.status };
  } else {
    return state;
  }
}

export default productCatalogs;
