// @flow

import type { GoogleAnalyticsItemDetailsType } from '../types/google_analytics';

declare var gtag: (
  trackingFunction: string,
  eventType: string,
  parameters?: Object,
) => void;

type GoogleAnalyticsItem = {
  [key: string]: any,
  google_analytics_item_details: GoogleAnalyticsItemDetailsType,
};

type SettingItem = {
  [key: string]: any,
  type: 'Setting',
  google_analytics_item_details: GoogleAnalyticsItemDetailsType,
};

type DiamondItem = {
  [key: string]: any,
  type: 'Diamond',
  google_analytics_item_details: GoogleAnalyticsItemDetailsType,
};

function gtagIfDefined(
  trackingFunction: string,
  eventType: string,
  parameters?: Object,
) {
  if (typeof gtag === 'function') {
    gtag(trackingFunction, eventType, parameters);
  }
}

function comboRingEventFactory(event: string) {
  return function (diamond: GoogleAnalyticsItem, setting: GoogleAnalyticsItem) {
    gtagIfDefined('event', event, {
      items: [mappedComboRingItem(diamond, setting)],
    });
  };
}

function itemEventFactory<T>(
  event: string,
  itemMapper: Function,
): (itemOrItems: T) => void {
  return function (itemOrItems: T) {
    gtagIfDefined('event', event, {
      items: itemMapper<T>(itemOrItems),
    });
  };
}

// when the factory output will be used in redux-saga sagas, we need to return a generator function
function itemEventFactoryUsingGenerator<T>(
  event: string,
  itemMapper: Function,
): (itemOrItems: T) => Generator<void, void, void> {
  return function* (itemOrItems: T) {
    gtagIfDefined('event', event, {
      items: itemMapper(itemOrItems),
    });

    yield;
  };
}

function itemEventWithExtraFieldsFactory<T>(
  event: string,
  itemMapper: Function,
  fieldMapper: Function,
): (itemOrItems: T) => void {
  return function (itemOrItems: T) {
    const items = itemMapper<T>(itemOrItems);

    gtagIfDefined('event', event, {
      items,
      ...fieldMapper(items),
    });
  };
}

function mappedComboRingItem(diamond: DiamondItem, setting: SettingItem) {
  const diamondData = diamond.google_analytics_item_details;
  const settingData = setting.google_analytics_item_details;

  // This is also implemented in ruby in GoogleAnalyticsHelper. Make sure to keep them in sync.
  return {
    item_id: `${diamondData.item_id} // ${settingData.item_id}`,
    item_name: `${diamondData.item_name} // ${settingData.item_name}`,
    item_category: 'built ring',
    // `price` is the correct attribute, not `retail_price`. That is because this data has alredy been mapped from the
    // original objects.
    price: parseFloat(diamondData.price) + parseFloat(settingData.price),
  };
}

function cartItemsAreComboRing(items: Array<GoogleAnalyticsItem>) {
  const itemTypeCount: { [string]: number } = items.reduce(
    (acc, curr) => {
      acc[curr.type.toLowerCase()] = (acc[curr.type.toLowerCase()] || 0) + 1;
      return acc;
    },
    ({}: { [string]: number }),
  );

  return (
    items.length === 2 &&
    itemTypeCount.diamond === 1 &&
    itemTypeCount.setting === 1
  );
}

function mappedCartItems(items: Array<GoogleAnalyticsItem>) {
  const dyorItems = items.filter((item) => item.is_dyor);
  const nonDyorItems = items.filter((item) => !item.is_dyor);

  const mappedItems: Array<GoogleAnalyticsItemDetailsType> = [];

  if (cartItemsAreComboRing(dyorItems)) {
    const itemsByType: {
      diamond?: DiamondItem,
      setting?: SettingItem,
    } = dyorItems.reduce(
      (acc, curr) => {
        if (curr.type === 'Diamond') {
          acc.diamond = curr;
        } else if (curr.type === 'Setting') {
          acc.setting = curr;
        }

        return acc;
      },
      ({}: { diamond?: DiamondItem, setting?: SettingItem }),
    );

    if (!itemsByType.diamond || !itemsByType.setting) {
      throw new Error('Missing diamond or setting in combo ring');
    }

    mappedItems.push(
      mappedComboRingItem(itemsByType.diamond, itemsByType.setting),
    );
  }

  nonDyorItems.forEach((item) => {
    mappedItems.push(item.google_analytics_item_details);
  });

  return mappedItems;
}

export const trackAddToCart: (item: GoogleAnalyticsItem) => void =
  itemEventFactory('add_to_cart', (item: GoogleAnalyticsItem) => [
    item.google_analytics_item_details,
  ]);

export const trackAddComboRingToCart: (
  diamond: DiamondItem,
  setting: SettingItem,
) => void = comboRingEventFactory('add_to_cart');

export const trackSelectItem: (item: GoogleAnalyticsItem) => void =
  itemEventFactory('select_item', (item: GoogleAnalyticsItem) => [
    item.google_analytics_item_details,
  ]);

export const trackViewItemList: (
  items: Array<GoogleAnalyticsItem>,
) => Generator<void, void, void> = itemEventFactoryUsingGenerator(
  'view_item_list',
  (items: Array<GoogleAnalyticsItem>) =>
    items.map((item) => item.google_analytics_item_details),
);

export const trackViewItem: (item: GoogleAnalyticsItem) => void =
  itemEventFactory('view_item', (item: GoogleAnalyticsItem) => [
    item.google_analytics_item_details,
  ]);

export const trackViewComboRingItem: (
  diamond: DiamondItem,
  setting: SettingItem,
) => void = comboRingEventFactory('view_item');

export const trackBeginCheckout: (items: Array<GoogleAnalyticsItem>) => void =
  itemEventWithExtraFieldsFactory(
    'begin_checkout',
    function (
      cartItems: Array<GoogleAnalyticsItem>,
    ): Array<GoogleAnalyticsItemDetailsType> {
      return mappedCartItems(cartItems);
    },
    (items: Array<GoogleAnalyticsItem>) => {
      return {
        currency: 'USD',
        value: items.reduce((acc, curr) => acc + parseFloat(curr.price), 0),
      };
    },
  );
