// @flow
import qs from 'qs';
import numeral from 'numeral';

import type { ObjectType } from '@types/misc';

const currencyFormat = '$0,0';

const diamondKeyOrder = [
  's',
  'cut',
  'clarity',
  'color',
  'carat',
  'fluorescence',
  'polish',
  'symmetry',
  '*',
  'p'
];

// needs to mirror the list in sagas/diamonds.js
const shortenedDiamondKeys = {
  shape: 's',
  price: 'p',
  depth: 'd',
  table: 't',
  lw_ratio: 'r',
  diamond_type: 'dt',
};

const shortenedSettingKeys = {
  style: 't',
  design: 'd',
  metal: 'm',
  sort: 'o',
  price: 'p',
  shape: 's'
};

const shortenedBandKeys = {
  style: 't',
  design: 'd',
  metal: 'm',
  sort: 'o',
  price: 'p',
  gender: 'g'
};

const settingKeyOrder = [
  'style',
  't',
  'metal',
  'm',
  'design',
  'd',
  'shape',
  's',
  'sort',
  'o',
  '*',
  'price',
  'p'
];
const bandKeyOrder = [
  'style',
  't',
  'metal',
  'm',
  'design',
  'd',
  'gender',
  'g',
  'sort',
  'o',
  '*',
  'price',
  'p'
];

const keySorter = (keyOrder) => {
  return (a, b) => {
    const defaultIndex = keyOrder.indexOf('*');
    let aIndex = keyOrder.indexOf(a);
    let bIndex = keyOrder.indexOf(b);

    aIndex = aIndex >= 0 ? aIndex : defaultIndex;
    bIndex = bIndex >= 0 ? bIndex : defaultIndex;

    return aIndex - bIndex;
  };
};

const sortKeysFn = (pageType) => {
  if (pageType === 'diamonds') {
    return keySorter(diamondKeyOrder);
  } else if (pageType === 'settings') {
    return keySorter(settingKeyOrder);
  } else if (pageType === 'bands') {
    return keySorter(bandKeyOrder);
  }
};

export function parseQueryString (query: string = '', pageType = 'unknown') {
  return qs.parse(query.replace('?', ''), { comma: true });
}

export function buildQueryString (params: ObjectType, pageType = 'unknown') {
  if (typeof window === 'undefined') return '';

  const paramsWithoutEmptyArrays = {};

  // We don't want empty arrays to result in empty query string values
  Object.keys(params).forEach((key) => {
    let shortestKey = key;
    const value = params[key];

    // We want to represent some data with shortened keys in the URL
    if (pageType === 'diamonds') {
      shortestKey = shortenedDiamondKeys[key] || key;
    } else if (pageType === 'settings') {
      shortestKey = shortenedSettingKeys[key] || key;
    } else if (pageType === 'bands') {
      shortestKey = shortenedBandKeys[key] || key;
    }

    if (!(Array.isArray(value) && value.length === 0)) {
      paramsWithoutEmptyArrays[shortestKey] = value;
    }
  });

  const stringifyOpts = {
    arrayFormat: 'comma', // use a=1,2,3 format
    sort: sortKeysFn(pageType), // sort keys based on what type of page we are on
    format: 'RFC1738', // supposed to use + instead of %20, doesn't seem to work
    skipNulls: true, // skip null values,
    encoder: (str, _defaultEncoder, _charset, type) => {
      if (type === 'value' && str && str.replace) {
        return str.replace(/%20|\s/g, '+');
      } else {
        return str;
      }
    }
  };

  // const queryObj = qs.parse(window.location.search.slice(1));
  // return `?${qs.stringify(
  //   { ...queryObj, ...paramsWithoutEmptyArrays },
  //   stringifyOpts
  // )}`;

  return `?${qs.stringify(paramsWithoutEmptyArrays, stringifyOpts)}`;
}

export function formatPrice (price: string | number) {
  return numeral(price).format(currencyFormat);
}
