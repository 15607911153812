// @flow
export function replaceState (
  stateObj: { [string]: string },
  title: string,
  url: string | void
) {
  if (typeof window === 'undefined') return;
  if (typeof window.history === 'undefined') return;

  window.history.replaceState(stateObj, title, url);

  var popStateEvent = new PopStateEvent('popstate', { state: stateObj });
  window.dispatchEvent(popStateEvent);
}
