// @flow
import AxiosInstanceFactory from '@src/utils/axiosInstance';
import {
  takeLatest,
  select,
  all,
  call,
  put,
  takeEvery
} from 'redux-saga/effects';
import { buildQueryString, parseQueryString } from '@src/utils/general';
import type { ObjectType } from '@types/misc';
import { handleError } from '../utils/errors';
import _cloneDeep from 'lodash/cloneDeep';
import { trackViewItemList } from '../utils/googleAnalytics';

import {
  encodeMetalSearchValue,
  decodeMetalSearchValue
} from '../utils/settings';

import * as routes from '@src/app_routes/settings';
import { replaceState } from '../utils/replaceState';

import type { SettingSearchResultType } from '@types/setting';

const getSettingSearchFilters = state => {
  const dyorDiamondId = state.ringBuilder.diamond.id;
  return {
    ..._cloneDeep(state.settingSearchFilters),
    fit_diamond: dyorDiamondId
  };
};
const getNextSearchPage = state => state.settingSearch.nextPage;
const getSearchExtentPrices = state => state.settingSearchExtents.price;

const axiosInstance = AxiosInstanceFactory();

type SettingsSearchResponseType = {
  data: {
    matches: Array<SettingSearchResultType>
  }
};

export default function* settingsIndex(): Generator<any, any, any> {
  yield all([
    takeLatest('SYNC_URI_WITH_SETTING_FILTERS', syncURIWithSettingFilters),
    takeLatest('SYNC_SETTING_FILTERS_FROM_URI', syncSettingFiltersFromUri),
    takeLatest('FETCH_SETTINGS_EXTENTS', fetchSettingsExtents),
    takeLatest('FETCH_SETTINGS', fetchSettings),
    takeLatest('FETCH_INITIAL_SETTINGS', fetchInitialSettings),
    takeLatest('SAVED_SETTINGS_SYNC_REQUESTED', savedSettingsSyncRequested),
    takeEvery('TOGGLE_FAVORITE_SETTING', toggleFavoriteSetting)
  ]);
}

export function* syncURIWithSettingFilters(
  action: any
): Generator<any, any, any> {
  if (!window) return;

  const filters = yield select(getSettingSearchFilters);

  const omittableKeys = ['sort', 'fit_diamond'];

  if (filters['metal']) {
    filters['metal'] = filters['metal'].map(val => {
      return encodeMetalSearchValue(val);
    });
  }

  omittableKeys.forEach(key => {
    if (filters.hasOwnProperty(key)) {
      delete filters[key];
    }
  });

  const uriWithoutQueryString =
    window.location.origin + window.location.pathname;

  const queryString = buildQueryString(filters, 'settings');

  const uri =
    queryString === '' || queryString === '?'
      ? uriWithoutQueryString
      : uriWithoutQueryString + queryString;

  replaceState({}, '', uri);

  if (action.callback) action.callback();
}

export function* syncSettingFiltersFromUri(
  action: ObjectType
): Generator<any, any, any> {
  try {
    if (typeof window === 'undefined') return;

    const url = window.location.href;

    const keys = [
      'style',
      't',
      'design',
      'd',
      'metal',
      'm',
      'price',
      'p',
      'shape',
      's',
      'sort',
      'o'
    ];

    const shortenedKeys = [
      ['style', 't'],
      ['design', 'd'],
      ['metal', 'm'],
      ['price', 'p'],
      ['shape', 's'],
      ['sort', 'o']
    ];

    const numericSortedKeys = ['price', 'p'];
    const enforcedArrayKeys = ['style', 't', 'design', 'd', 'metal', 'm'];

    const params = parseQueryString(url.replace(/^.*?\?/, ''));
    const filters = {};

    keys.forEach(key => {
      if (params[key]) filters[key] = params[key];
    });

    numericSortedKeys.forEach(key => {
      if (filters[key]) {
        filters[key] = filters[key].map(Number).sort((a, b) => a - b);
      }
    });

    enforcedArrayKeys.forEach(key => {
      if (filters.hasOwnProperty(key) && !Array.isArray(filters[key])) {
        // The value is not an array, so put it in one
        filters[key] = [filters[key]];
      }
    });

    shortenedKeys.forEach(keyPair => {
      if (filters.hasOwnProperty(keyPair[1])) {
        filters[keyPair[0]] = filters[keyPair[1]];
        delete filters[keyPair[1]];
      }
    });

    if (filters['metal']) {
      filters['metal'] = filters['metal'].map(val => {
        return decodeMetalSearchValue(val);
      });
    }

    // If there are no params, don't reset the params
    if (Object.keys(filters).length > 0) {
      yield put({ type: 'RESET_SETTING_FILTERS', filters });
    }

    const updatedFilters = yield select(getSettingSearchFilters);

    if (action.callback) action.callback(updatedFilters);
  } catch (error) {
    yield put({
      type: 'SYNC_SETTING_FILTERS_FROM_URI_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* fetchInitialSettings(): Generator<any, any, any> {
  try {
    yield put({ type: 'RESET_NEXT_SETTINGS_PAGE' });
    yield put({
      type: 'FETCH_SETTINGS',
      payload: {
        fireViewItemListEvent: true,
      },
    });
  } catch (error) {
    yield put({
      type: 'FETCH_INITIAL_SETTINGS_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* fetchSettingsExtents(): Generator<any, any, any> {
  try {
    yield put({ type: 'FETCH_SETTINGS_EXTENTS_STARTED' });

    const filters = yield select(getSettingSearchFilters);
    const res = yield call(axiosInstance.get, routes.apiExtents(filters));

    yield put({ type: 'UPDATE_SETTINGS_EXTENTS', payload: res.data });
    yield put({ type: 'FETCH_SETTINGS_EXTENTS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'FETCH_SETTINGS_EXTENTS_FAILED',
      error: error.message,
    });
    handleError(error);
  }
}

export function* fetchSettings(action): Generator<any, any, any> {
  try {
    const actionPayload = action.payload || {};
    const fireViewItemListEvent = actionPayload.fireViewItemListEvent;

    yield put({ type: 'FETCH_SETTINGS_STARTED' });

    const filters = yield select(getSettingSearchFilters);
    const nextPage = yield select(getNextSearchPage);
    const resetResults = nextPage === 1;

    if (resetResults) {
      yield put({
        type: 'CLEAR_SETTINGS',
        payload: []
      });

      yield call(fetchSettingsExtents);
    }

    const updatedPriceExtents = yield select(getSearchExtentPrices);

    if (filters.price) {
      const [filterMin, filterMax] = filters.price;

      if (updatedPriceExtents[0] > filters.price[0]) {
        filters.price[0] = updatedPriceExtents[0];
      }

      if (updatedPriceExtents[0] > filters.price[1]) {
        filters.price[1] = updatedPriceExtents[0];
      }

      if (updatedPriceExtents[1] < filters.price[0]) {
        filters.price[0] = updatedPriceExtents[1];
      }

      if (updatedPriceExtents[1] < filters.price[1]) {
        filters.price[1] = updatedPriceExtents[1];
      }

      // As a last step, make sure that the newly "limited" price filter will
      // not be outside of the range the user has selected. Previously, the min
      // or max value of the extents would be used and cause the display of an
      // item outside of the user's selected price range.
      if (filters.price[0] < filterMin) {
        filters.price[0] = filterMin;
      }

      if (filters.price[1] < filterMin) {
        filters.price[1] = filterMin;
      }

      if (filters.price[0] > filterMax) {
        filters.price[0] = filterMax;
      }

      if (filters.price[1] > filterMax) {
        filters.price[1] = filterMax;
      }
    }

    const res = yield call(
      axiosInstance.get,
      routes.apiSearch({ page: nextPage, ...filters })
    );

    // If hasOwnProperty is not used a value of 0 is considered falsy even though that's a valid matchCount
    if (res.data.hasOwnProperty('matchCount')) {
      yield all([
        put({
          type: 'SETTINGS_RECEIVED',
          payload: res.data.matches
        }),
        put({
          type: 'SETTINGS_COUNT_RECEIVED',
          payload: res.data.matchCount
        }),
      ]);
    } else {
      yield put({
        type: 'SETTINGS_RECEIVED',
        payload: res.data.matches
      });
    }

    if (fireViewItemListEvent) {
      yield call(trackViewItemList, res.data.matches);
    }

    yield put({ type: 'INCREMENT_NEXT_SETTINGS_PAGE' });
    yield put({ type: 'FETCH_SETTINGS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'FETCH_SETTINGS_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* savedSettingsSyncRequested(
  _action: any
): Generator<any, any, any> {
  try {
    const res = yield call(axiosInstance.get, routes.getSaved);

    yield put({ type: 'SERVER_FAVORITED_SETTINGS_UPDATED', data: res.data });
  } catch (error) {
    yield put({ type: 'SAVED_SETTINGS_SYNC_FAILED', error: error.message });
    handleError(error);
  }
}

export function* toggleFavoriteSetting(action: any): Generator<any, any, any> {
  try {
    const res = yield call(
      axiosInstance.post,
      routes.savePath(action.item.sku)
    );
    yield put({
      type: 'SERVER_FAVORITED_SETTINGS_UPDATED',
      data: res.data
    });

    if (action.redirectTo) {
      if (typeof window !== 'undefined') window.location = action.redirectTo;
    }
  } catch (error) {
    if (window) {
      window.alert(
        'There was a problem saving the item.\n\nPlease make sure you have cookies enabled and refresh the page. If the problem continues, please try using a different browser such as Google Chrome.'
      );
    }

    yield put({
      type: 'SERVER_FAVORITED_SETTINGS_UPDATE_FAILED',
      error: error.message
    });
    handleError(error);
  }
}
