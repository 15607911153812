// @flow
import { select, takeLatest, all, put, call } from 'redux-saga/effects';
import { handleError } from '../utils/errors';

export default function* diamondSearch(): Generator<any, any, any> {
  yield all([
    takeLatest('TOGGLE_DIAMOND_SHAPE', toggleDiamondShape),
    takeLatest('DIAMOND_SLIDER_UPDATE', diamondSliderUpdate),
    takeLatest('DIAMOND_HEADER_ITEM_SELECT', diamondHeaderItemSelect),
    takeLatest('RESET_DIAMOND_SEARCH_FILTERS', resetDiamondSearchFilters),
    takeLatest(
      'CLEAR_DIAMOND_SEARCH_FILTERS_CLICK',
      clearDiamondSearchFiltersClick
    ),
    takeLatest('CHANGE_DIAMOND_TYPE', changeDiamondType),
  ]);
}

export function* toggleDiamondShape(action: Object): Generator<any, any, any> {
  const state = yield select();

  try {
    if (state.diamondSearchFilters.shape.length == 1 && state.diamondSearchFilters.shape[0] == action.shape)
      return;

    yield put({ type: 'UPDATE_SHAPE', item: action.shape });
    yield put({
      type: 'NEW_DIAMOND_SEARCH_STARTED',
      looseDiamonds: action.looseDiamonds
    }); // place this early as possible so we don't see "0 diamonds found"
    yield put({
      type: 'UPDATE_DIAMOND_SEARCH_PAGE',
      item: 1,
      looseDiamonds: action.looseDiamonds
    });
    yield put({
      type: 'CLEAR_DIAMOND_SEARCH_RESULTS',
      looseDiamonds: action.looseDiamonds
    });
    yield put({
      type: 'FETCH_DIAMOND_SEARCH_EXTENTS',
      looseDiamonds: action.looseDiamonds
    });
    yield put({ type: 'FETCH_DIAMONDS', looseDiamonds: action.looseDiamonds });

    if (!action.disableUrlSync) {
      yield put({
        type: 'SYNC_URI_WITH_DIAMOND_FILTERS',
        looseDiamonds: action.looseDiamonds
      });
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (error) {
    yield put({ type: 'TOGGLE_DIAMOND_SHAPE_FAILED', error: error.message });
    handleError(error);
  }
}

export function* diamondSliderUpdate(action: Object): Generator<any, any, any> {
  try {
    const actionDetail = action.action;
    yield put({ type: actionDetail.type, item: actionDetail.item });
    yield put({
      type: 'NEW_DIAMOND_SEARCH_STARTED',
      looseDiamonds: action.looseDiamonds
    }); // place this early as possible so we don't see "0 diamonds found"
    yield put({
      type: 'UPDATE_DIAMOND_SEARCH_PAGE',
      item: 1,
      looseDiamonds: action.looseDiamonds
    });
    yield put({
      type: 'CLEAR_DIAMOND_SEARCH_RESULTS',
      looseDiamonds: action.looseDiamonds
    });
    yield put({
      type: 'FETCH_DIAMOND_SEARCH_EXTENTS',
      looseDiamonds: action.looseDiamonds
    });
    yield put({ type: 'FETCH_DIAMONDS', looseDiamonds: action.looseDiamonds });

    if (!action.disableUrlSync) {
      yield put({
        type: 'SYNC_URI_WITH_DIAMOND_FILTERS',
        looseDiamonds: action.looseDiamonds
      });
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (error) {
    yield put({ type: 'DIAMOND_SLIDER_UPDATE_FAILED', error: error.message });
    handleError(error);
  }
}

export function* diamondHeaderItemSelect(
  action: Object
): Generator<any, any, any> {
  try {
    yield put({
      type: 'SORT_DIAMOND_RESULTS',
      item: action.item,
      looseDiamonds: action.looseDiamonds
    });
    yield put({
      type: 'UPDATE_DIAMOND_SEARCH_PAGE',
      item: 1,
      looseDiamonds: action.looseDiamonds
    });
    yield put({
      type: 'FETCH_DIAMONDS',
      suppressSearchingMessage: true,
      looseDiamonds: action.looseDiamonds
    });
  } catch (error) {
    yield put({
      type: 'DIAMOND_HEADER_ITEM_SELECT_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* resetDiamondSearchFilters(
  action: any
): Generator<any, any, any> {
  try {
    yield put({
      type: 'CLEAR_DIAMOND_SEARCH_FILTERS',
      looseDiamonds: action.looseDiamonds
    });
  } catch (error) {
    yield put({
      type: 'RESET_DIAMOND_SEARCH_FILTERS_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* clearDiamondSearchFiltersClick(
  action: any
): Generator<any, any, any> {
  try {
    yield put({
      type: 'CLEAR_DIAMOND_SEARCH_FILTERS',
      looseDiamonds: action.looseDiamonds
    });
    yield put({
      type: 'CLEAR_DIAMOND_SEARCH_RESULTS',
      looseDiamonds: action.looseDiamonds
    });

    if (!action.disableUrlSync) {
      yield put({
        type: 'SYNC_URI_WITH_DIAMOND_FILTERS',
        looseDiamonds: action.looseDiamonds
      });
    }

    yield put({
      type: 'FETCH_DIAMOND_SEARCH_EXTENTS',
      looseDiamonds: action.looseDiamonds
    });
    yield put({ type: 'FETCH_DIAMONDS', looseDiamonds: action.looseDiamonds });

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (error) {
    yield put({
      type: 'CLEAR_DIAMOND_SEARCH_FILTERS_CLICK_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* changeDiamondType(action: any): Generator<any, any, any> {
  try {
    yield put({
      type: 'UPDATE_DIAMOND_SEARCH_TYPE',
      item: action.item,
    });

    yield put({
      type: 'NEW_DIAMOND_SEARCH_STARTED',
      looseDiamonds: action.looseDiamonds,
    });
    yield put({
      type: 'UPDATE_DIAMOND_SEARCH_PAGE',
      item: 1,
      looseDiamonds: action.looseDiamonds,
    });
    yield put({
      type: 'CLEAR_DIAMOND_SEARCH_RESULTS',
      looseDiamonds: action.looseDiamonds,
    });
    yield put({
      type: 'FETCH_DIAMOND_SEARCH_EXTENTS',
      looseDiamonds: action.looseDiamonds,
    });
    yield put({ type: 'FETCH_DIAMONDS', looseDiamonds: action.looseDiamonds });

    if (!action.disableUrlSync) {
      yield put({
        type: 'SYNC_URI_WITH_DIAMOND_FILTERS',
        looseDiamonds: action.looseDiamonds,
      });
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (error) {
    yield put({
      type: 'CHANGE_DIAMOND_TYPE_FAILED',
      error: error.message,
    });
    handleError(error);
  }
}
