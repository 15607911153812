// @flow
import { getDiamondDescription } from '@src/utils/diamonds';
import type { RecentlyViewedDiamondSummaryType } from '@types/diamond';

type StateType = Array<RecentlyViewedDiamondSummaryType>;

export default function(state: StateType = [], action: any): StateType {
  switch (action.type) {
    case 'PUSH_RECENTLY_VIEWED_DIAMOND':
      const summary = {
        list_service_diamond_id: action.item.list_service_diamond_id,
        description: getDiamondDescription(action.item),
        price: action.item.retail_price,
        shape: action.item.shape
      };

      const newDiamondId = summary.list_service_diamond_id;

      const existingIndexForDiamond = state.findIndex(
        diamond => diamond.list_service_diamond_id === newDiamondId
      );

      if (existingIndexForDiamond >= 0) {
        // Diamond is already in recents
        const recentsWithoutThisDiamond = state.filter(diamond => {
          return diamond.list_service_diamond_id !== newDiamondId;
        });

        return [summary, ...recentsWithoutThisDiamond];
      } else {
        // Diamond is not currently listed in the recents, we need to add it.
        // We keep 4 items in this array -- the first item is the one currently
        // being viewed and the rest are your history.
        return [summary, ...state].slice(0, 4);
      }
    default:
      return state;
  }
}
