// @flow
import { style, media } from 'typestyle';

type PropsType = {
  count: number
};

const linkStyles = style(
  media(
    { maxWidth: 736 },
    {
      marginRight: 13
    }
  ),
  { color: '#a9bfda !important' }
);

const spanStyles = style(
  media(
    { maxWidth: 420 },
    {
      display: 'none'
    }
  )
);

export default ({ count }: PropsType) => {
  return (
    <a href="/saved" className={linkStyles}>
      <span>
        {count} Saved
        <span className={spanStyles}> {count === 1 ? 'Item' : 'Items'}</span>
      </span>
    </a>
  );
};
