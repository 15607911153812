// @flow
import { buildQueryString } from '@src/utils/general';

type combinedStepParams = {
  diamond?: number,
  setting?: string
};

export const apiSettingPath = '/api/ring_builder/setting.json';
export const apiSaveSettingPath = '/api/ring_builder/setting.json';
export const apiClearSettingPath = '/api/ring_builder/setting.json';
export const apiDiamondPath = '/api/ring_builder/diamond.json';
export const apiSaveDiamondPath = '/api/ring_builder/diamond.json';
export const apiClearDiamondPath = '/api/ring_builder/diamond.json';
export const apiCompatibilityCheck = '/api/engagement_ring_compatibility.json';
export const combinedStep = (params: combinedStepParams = {}) => {
  const path = '/your-ring';

  if (params.diamond && params.setting) {
    return `${path}${buildQueryString(params)}`;
  } else {
    return path;
  }
};
export const apiSaveDiamondAndSetting =
  '/api/ring_builder/diamond_and_setting.json';
