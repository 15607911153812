// @flow
import { compose, createStore, combineReducers, applyMiddleware } from 'redux';
import {
  persistCombineReducers,
  persistStore,
  persistReducer
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import ringBuilder from '@src/reducers/ringBuilder';
import cart from '@src/reducers/cart';
import diamonds from '@src/reducers/diamonds';
import editingRing from '@src/reducers/editingRing';
import diamondSearch from '@src/reducers/diamondSearch';
import diamondSearchFilters from '@src/reducers/diamondSearchFilters';
import diamondSearchExtents from '@src/reducers/diamondSearchExtents';
import bands from '@src/reducers/bands';
import bandSearch from '@src/reducers/bandSearch';
import bandSearchFilters from '@src/reducers/bandSearchFilters';
import bandSearchExtents from '@src/reducers/bandSearchExtents';
import settings from '@src/reducers/settings';
import settingSearch from '@src/reducers/settingSearch';
import settingSearchFilters from '@src/reducers/settingSearchFilters';
import settingSearchExtents from '@src/reducers/settingSearchExtents';
import recentlyViewedDiamonds from '@src/reducers/recentlyViewedDiamonds';
import recentlyViewedSettings from '@src/reducers/recentlyViewedSettings';
import diamondComparison from '@src/reducers/diamondComparison';
import settingFavorites from '@src/reducers/settingFavorites';
import ringSize from '@src/reducers/ringSize';
import settingsFromServer from '@src/reducers/settingsFromServer';
import productCatalogs from '@src/reducers/productCatalogs';

import type { ObjectType, RailsContextType } from '@types/misc';

const persistConfig = {
  key: '_lumera_',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'diamonds',
    'diamondComparison',
    'diamondSearch',
    'settings',
    'settingSearch',
    'settingSearchExtents',
    'bands',
    'bandSearch',
    'bandSearchExtents',
    'ringBuilder',
    'settingsFromServer'
  ]
};

const ringBuilderPersistConfig = {
  key: '_lumera_ring_builder_',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['firstStep']
};

export default (props: ObjectType = {}, railsContext: RailsContextType) => {
  let composeEnhancers;
  let store;
  const reducers = {
    ringBuilder: persistReducer(ringBuilderPersistConfig, ringBuilder),
    cart,
    editingRing,
    diamonds,
    diamondSearch,
    diamondSearchFilters: diamondSearchFilters(railsContext),
    diamondSearchExtents,
    bands,
    bandSearch,
    bandSearchFilters,
    bandSearchExtents,
    settings,
    settingSearch,
    settingSearchFilters,
    settingSearchExtents,
    recentlyViewedDiamonds,
    recentlyViewedSettings,
    diamondComparison,
    settingFavorites,
    ringSize,
    settingsFromServer,
    productCatalogs
  };

  if (railsContext.serverSide) {
    const combinedReducers = combineReducers(reducers);
    composeEnhancers = compose;
    store = createStore(combinedReducers, props);
  } else {
    const rootSaga = require('@src/sagas').default;
    const sagaMiddleware = createSagaMiddleware();
    const combinedReducers = persistCombineReducers(persistConfig, reducers);
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    // $FlowFixMe
    store = createStore(
      combinedReducers,
      props,
      composeEnhancers(applyMiddleware(sagaMiddleware))
    );

    sagaMiddleware.run(rootSaga);

    persistStore(store, null, () => {
      store.getState();
    });
  }

  return store;
};
