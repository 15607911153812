// @flow
type StateType = {
  preferredRingSize?: number
};

const defaultState = {};

export default function ringSize(state: StateType = defaultState, action: any) {
  switch (action.type) {
    case 'SET_PREFERRED_RING_SIZE':
      return {
        ...state,
        preferredRingSize: action.payload
      };

    default:
      return state;
  }
}
