// @flow
import { useEffect } from 'react';
import useHydrated from '@hooks/useHydrated';

function ServerDataSynchronizer () {
  const hydrated = useHydrated();

  useEffect(
    () => {
      if (!hydrated) return;

      const store = ReactOnRails.getStore('SharedReduxStore');
      store.dispatch({ type: 'CART_SYNC_REQUESTED' });
      store.dispatch({ type: 'SAVED_DIAMONDS_SYNC_REQUESTED' });
      store.dispatch({ type: 'SAVED_SETTINGS_SYNC_REQUESTED' });
    },
    [hydrated]
  );

  return null;
}

export default () => <ServerDataSynchronizer />;
