// @flow
type StateType = {
  shape: Array<string>,
  price: Array<number>,
  carat: Array<number>
};

const initialState = {
  shape: ['B', 'P', 'E', 'M', 'O', 'R', 'PR', 'C', 'H', 'AS'],
  price: [100, 50000000],
  carat: [0.1, 100]
};

function reducer (state, action) {
  switch (action.type) {
    case 'DIAMOND_SEARCH_EXTENTS_RECEIVED':
      return action.item;
    case 'CLEAR_DIAMOND_SEARCH_EXTENTS':
      return {};
    default:
      return state;
  }
}

export default function diamondSearchExtents (state: StateType = initialState, action: any) {
  // If any extents are set on the server, it will squash the other defaults
  // so we need to add them back in
  return reducer({
    ...initialState,
    ...state
  }, action);
}
