// @flow
import qs from 'qs';

import type { ObjectType } from '@types/misc';

export function detailPath (
  band: { vendor_sku: string, url_segment_description: string } = {
    vendor_sku: '',
    url_segment_description: ''
  },
  params: ObjectType = {}
) {
  const queryString: string = qs.stringify(params);
  const { vendor_sku: id, url_segment_description } = band;

  if (!id) return '';

  let path;

  if (url_segment_description) {
    path = `/wedding-bands/detail/${url_segment_description}/${id}`;
  } else {
    path = `/wedding-bands/detail/${id}`;
  }

  if (queryString.length > 0) {
    return `${path}?${queryString}`;
  } else {
    return path;
  }
}

export function searchPath () {
  return '/wedding-bands/search';
}

export function apiSearch (params: Object = {}) {
  const queryString: string = qs.stringify(params, { arrayFormat: 'brackets' });
  const path = '/api/wedding-bands.json';

  if (queryString.length > 0) {
    return `${path}?${queryString}`;
  } else {
    return path;
  }
}

export function apiExtents (params: Object = {}) {
  const queryString: string = qs.stringify(params, { arrayFormat: 'brackets' });
  const path = '/api/wedding-bands/extents.json';

  if (queryString.length > 0) {
    return `${path}?${queryString}`;
  } else {
    return path;
  }
}

export function apiDetail (sku: string, params: ObjectType = {}) {
  if (!sku) throw new Error('SKU is required');

  const queryString: string = qs.stringify(params);

  if (queryString.length > 0) {
    return `/api/wedding-bands/${sku}/detail.json?${queryString}`;
  } else {
    return `/api/wedding-bands/${sku}/detail.json`;
  }
}

export function apiExtendedDetail (sku: string, params: ObjectType = {}) {
  if (!sku) throw new Error('SKU is required');

  const queryString: string = qs.stringify(params);

  if (queryString.length > 0) {
    return `/api/wedding-bands/${sku}/extended_detail.json?${queryString}`;
  } else {
    return `/api/wedding-bands/${sku}/extended_detail.json`;
  }
}

export function apiBandUpdatedDeliveryDate (sku: string) {
  if (!sku) throw new Error('SKU is required');

  return `/api/wedding-bands/${sku}/updated_delivery_date.json`;
}

export function savePath (sku: string) {
  const path = `/api/wedding-bands/${sku}/save.json`;

  return path;
}

export const getSaved = '/api/wedding-bands/saved.json';
