// @flow
import { style, media } from 'typestyle';

type PropsType = {
  count: number
};

const spanStyles = style(
  media(
    { maxWidth: 420 },
    {
      display: 'none'
    }
  )
);

export default ({ count }: PropsType) => {
  return (
    <a className='shopping-bag-link' href='/orders/cart'>
      <span key='link'>
        <span className={spanStyles}>Shopping </span> Bag ({count}){' '}
      </span>
      <i className='fa fa-chevron-right' key='chevron' />
    </a>
  );
};
