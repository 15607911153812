// @flow

declare var Honeybadger: any;

export function handleError (e: Error) {
  if (typeof Honeybadger !== 'undefined') {
    Honeybadger.notify(e);
  } else {
    throw e;
  }
}
