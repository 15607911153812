// @flow
import AxiosInstanceFactory from '@src/utils/axiosInstance';
import {
  takeEvery,
  takeLatest,
  select,
  all,
  call,
  put
} from 'redux-saga/effects';
import { buildQueryString, parseQueryString } from '@src/utils/general';
import * as routes from '@src/app_routes/diamonds';
import { valuesWithinExtents, config } from '@src/utils/sliders';
import type { ObjectType } from '@types/misc';
import { handleError } from '../utils/errors';
import { replaceState } from '../utils/replaceState';
import { trackViewItemList } from '../utils/googleAnalytics';

const getDiamondSearchFilters = state => {
  const dyorSettingSku = state.ringBuilder.setting.sku;
  return { ...state.diamondSearchFilters, fit_setting: dyorSettingSku };
};
const getDiamondSearchExtents = state => state.diamondSearchExtents;
const getDiamondSearch = state => state.diamondSearch;
const getPreviousSortColumn = state => state.diamondComparison.sortColumn;
const getPreviousSortDirection = state => state.diamondComparison.sortDirection;

const axiosInstance = AxiosInstanceFactory();

export default function* watchDiamonds(): Generator<any, any, any> {
  yield all([
    takeLatest('FETCH_INITIAL_DIAMONDS', fetchInitialDiamonds),
    takeEvery('FETCH_DIAMONDS', fetchDiamonds),
    takeLatest('FETCH_DIAMOND_SEARCH_EXTENTS', fetchDiamondSearchExtents),
    takeLatest('SYNC_URI_WITH_DIAMOND_FILTERS', syncURIWithDiamondFilters),
    takeEvery('TOGGLE_DIAMOND_FOR_COMPARISON', toggleDiamondForComparison),
    takeLatest('SORT_DIAMOND_COMPARISON', sortDiamondComparison),
    takeLatest('SAVED_DIAMONDS_SYNC_REQUESTED', savedDiamondsSyncRequested),
    takeLatest('SYNC_DIAMOND_FILTERS_FROM_URI', syncDiamondFiltersFromUri),
    takeLatest('SORT_DIAMOND_RESULTS', sortDiamondResults)
  ]);
}

export function* fetchDiamonds(
  options: Object = { suppressSearchingMessage: false, fireViewItemListEvent: false }
): Generator<any, any, any> {
  try {
    const getDiamondPage = state => state.diamondSearch.page;
    const filters = { ...(yield select(getDiamondSearchFilters)) }; // RETURN A NEW OBJECT SO WE DON'T MODIFY STATE
    const extents = { ...(yield select(getDiamondSearchExtents)) }; // RETURN A NEW OBJECT SO WE DON'T MODIFY STATE
    const searchSettings = { ...(yield select(getDiamondSearch)) }; // RETURN A NEW OBJECT SO WE DON'T MODIFY STATE
    const page = yield select(getDiamondPage);
    const action = page === 1 ? 'DIAMONDS_RECEIVED_RESET' : 'DIAMONDS_RECEIVED';
    const isNewSearch = page === 1;
    const sort = {};

    if (options.looseDiamonds) {
      filters['loose_diamonds'] = true;
      delete filters.fit_setting;
    }

    const numericFilterKeys = ['price', 'carat', 'depth', 'table', 'lw_ratio'];

    yield put({ type: 'FETCH_DIAMONDS_STARTED' });
    if (isNewSearch && !options.suppressSearchingMessage) {
      yield put({ type: 'NEW_DIAMOND_SEARCH_STARTED' });
    }

    numericFilterKeys.forEach(key => {
      if (filters[key]) {
        const keyConfig = config[key];
        filters[key] = valuesWithinExtents(
          filters[key],
          extents[key],
          keyConfig.stepSize,
          // $FlowFixMe
          keyConfig.margin,
          keyConfig.numericPrecision
        ).values;
      }
    });

    if (searchSettings.sortBy) {
      sort.sort_by = searchSettings.sortBy;
      sort.sort_dir = searchSettings.sortDir;
    }

    const res = yield call(
      axiosInstance.get,
      routes.apiIndex({ ...filters, ...sort, page })
    );

    // If hasOwnProperty is not used a value of 0 is considered falsy even though that's a valid matchCount
    if (res.data.hasOwnProperty('matchCount')) {
      yield all([
        put({
          type: action,
          item: res.data.matches
        }),
        put({
          type: 'DIAMOND_COUNT_RECEIVED',
          item: res.data.matchCount
        })
      ]);
    } else {
      yield put({
        type: action,
        item: res.data.matches
      });
    }

    yield put({ type: 'FETCH_DIAMONDS_SUCCESS' });

    if (options.fireViewItemListEvent) {
      yield call(trackViewItemList, res.data.matches);
    }

    if (isNewSearch && !options.suppressSearchingMessage) {
      yield put({ type: 'NEW_DIAMOND_SEARCH_FINISHED' });
    }
  } catch (error) {
    yield put({ type: 'FETCH_DIAMONDS_FAILED', error: error.message });
    handleError(error);
  }
}

export function* fetchInitialDiamonds(
  action: Object
): Generator<any, any, any> {
  try {
    yield all([
      call(fetchDiamondSearchExtents, {
        looseDiamonds: action.looseDiamonds
      }),
      call(fetchDiamonds, { looseDiamonds: action.looseDiamonds, fireViewItemListEvent: true })
    ]);
  } catch (error) {
    yield put({ type: 'FETCH_INITIAL_DIAMONDS_FAILED', error: error.message });
    handleError(error);
  }
}

export function* fetchDiamondSearchExtents(
  action: any
): Generator<any, any, any> {
  try {
    const filters = yield select(getDiamondSearchFilters);

    if (action.looseDiamonds) {
      filters['loose_diamonds'] = true;
      delete filters.fit_setting;
    }

    const res = yield call(axiosInstance.post, '/api/diamonds/ranges', filters);

    const numericData = {};

    for (let key in res.data) {
      numericData[key] = res.data[key].map(Number);
    }

    yield put({
      type: 'DIAMOND_SEARCH_EXTENTS_RECEIVED',
      item: res.data
    });

    if (action.callback) action.callback();
  } catch (error) {
    yield put({
      type: 'FETCH_DIAMOND_SEARCH_EXTENTS_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* syncURIWithDiamondFilters(
  action: any
): Generator<any, any, any> {
  if (!window) return;

  const filters = yield select(getDiamondSearchFilters);

  if (action.looseDiamonds) {
    delete filters.fit_setting;
  }

  const uri =
    window.location.origin +
    window.location.pathname +
    buildQueryString(filters, 'diamonds');

  replaceState({}, '', uri);

  if (action.callback) action.callback();
}

export function* toggleDiamondForComparison(
  action: any
): Generator<any, any, any> {
  try {
    const res = yield call(
      axiosInstance.post,
      `/api/diamonds/${action.item}/save`
    );
    yield put({
      type: 'SERVER_SAVED_DIAMONDS_UPDATED',
      data: res.data
    });

    if (action.redirectTo) {
      if (typeof window !== 'undefined') window.location = action.redirectTo;
    }
  } catch (error) {
    if (window) {
      window.alert(
        'There was a problem saving the item.\n\nPlease make sure you have cookies enabled and refresh the page. If the problem continues, please try using a different browser such as Google Chrome.'
      );
    }

    yield put({
      type: 'TOGGLE_DIAMOND_FOR_COMPARISON_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* sortDiamondComparison(action: any): Generator<any, any, any> {
  try {
    const previousColumn = yield select(getPreviousSortColumn);
    const previousSortDirection = yield select(getPreviousSortDirection);
    const sameColumn = previousColumn === action.column;
    const direction =
      sameColumn && previousSortDirection === 'asc' ? 'desc' : 'asc';

    const res = yield call(
      axiosInstance.post,
      routes.sortComparison({
        column: action.column,
        direction: direction
      })
    );

    yield put({ type: 'SERVER_SAVED_DIAMONDS_UPDATED', data: res.data });
    yield put({
      type: 'DIAMOND_COMPARISON_SORTED',
      column: action.column,
      direction: direction
    });
  } catch (error) {
    yield put({ type: 'DIAMOND_COMPARISON_SORT_FAILED', error: error.message });
    handleError(error);
  }
}

export function* savedDiamondsSyncRequested(
  _action: any
): Generator<any, any, any> {
  var res;

  try {
    res = yield call(axiosInstance.get, routes.getSavedDiamonds);

    yield put({ type: 'SERVER_SAVED_DIAMONDS_UPDATED', data: res.data });
  } catch (error) {
    yield put({ type: 'SAVED_DIAMONDS_SYNC_FAILED', error: error.message });
    handleError(error);
  }
}

export function* syncDiamondFiltersFromUri(
  action: ObjectType
): Generator<any, any, any> {
  try {
    const keys = [
      'shape',
      's',
      'price',
      'p',
      'carat',
      'polish',
      'symmetry',
      'fluorescence',
      'cut',
      'color',
      'clarity',
      'depth',
      'd',
      'table',
      't',
      'lw_ratio',
      'r',
      'diamond_type',
      'dt',
    ];

    // needs to mirror the list in utils/general.js
    const shortenedKeys = [
      ['shape', 's'],
      ['price', 'p'],
      ['depth', 'd'],
      ['table', 't'],
      ['lw_ratio', 'r'],
      ['diamond_type', 'dt'],
    ];

    const numericSortedKeys = [
      'price',
      'p',
      'carat',
      'depth',
      'd',
      'table',
      't',
      'lw_ratio',
      'r'
    ];
    const enforcedArrayKeys = [
      'shape',
      's',
      'cut',
      'color',
      'clarity',
      'polish',
      'symmetry',
      'fluorescence'
    ];

    const params = parseQueryString(action.url.replace(/^.*?\?/, ''));
    const filters = {};

    keys.forEach(key => {
      if (params[key]) filters[key] = params[key];
    });

    numericSortedKeys.forEach(key => {
      if (filters[key]) {
        filters[key] = filters[key].map(Number).sort((a, b) => a - b);
      }
    });

    enforcedArrayKeys.forEach(key => {
      if (filters.hasOwnProperty(key) && !Array.isArray(filters[key])) {
        // The value is not an array, so put it in one
        filters[key] = [filters[key]];
      }
    });

    shortenedKeys.forEach(keyPair => {
      if (filters.hasOwnProperty(keyPair[1])) {
        filters[keyPair[0]] = filters[keyPair[1]];
        delete filters[keyPair[1]];
      }
    });

    // If there are no params, don't reset the params
    if (Object.keys(filters).length > 0) {
      yield put({ type: 'RESET_DIAMOND_FILTERS', filters });
    }

    if (action.callback) action.callback();
  } catch (error) {
    yield put({
      type: 'SYNC_DIAMOND_FILTERS_FROM_URI_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* sortDiamondResults(
  action: ObjectType
): Generator<any, any, any> {
  try {
    yield put({ type: 'UPDATE_DIAMOND_SEARCH_COLUMN', item: action.item });
    yield put({
      type: 'SYNC_URI_WITH_DIAMOND_FILTERS',
      looseDiamonds: action.looseDiamonds
    });
  } catch (error) {
    yield put({ type: 'SORT_DIAMOND_RESULTS_FAILED', error: error.message });
    handleError(error);
  }
}
