// @flow
import type { BandType } from '@types/band';

type StateType = {
  matches: Array<BandType>,
  matchCount: number
};

const defaultState = {
  matches: [],
  matchCount: 0
};

export default function bands(state: StateType = defaultState, action: any) {
  switch (action.type) {
    case 'BANDS_RECEIVED':
      return {
        ...state,
        matches: [...state.matches, ...action.payload]
      };

    case 'BANDS_COUNT_RECEIVED':
      return {
        ...state,
        matchCount: action.payload
      };

    case 'CLEAR_BANDS':
      return { ...state, matches: [], matchCount: 0 };

    default:
      return state;
  }
}
