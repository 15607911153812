// @flow
import numeral from 'numeral';

import type { DiamondType } from '@types/diamond';

const caratFormat = '0.00';

function descriptionizedShape (shape: string) {
  return {
    B: 'Round',
    P: 'Pear-Shaped',
    E: 'Emerald-Cut',
    M: 'Marquise-Cut',
    O: 'Oval',
    R: 'Radiant-Cut',
    PR: 'Princess-Cut',
    C: 'Cushion-Cut',
    H: 'Heart-Shaped',
    AS: 'Asscher-Cut'
  }[shape];
}


export function getDiamondDescription (diamond: DiamondType) {
  const diamondDescriptor = diamond.natural ? 'Diamond' : 'Lab-Created Diamond';
  return `${numeral(diamond.carat).format(caratFormat)} Carat ${descriptionizedShape(diamond.shape)} ${diamondDescriptor}`;
}
