// @flow
import _includes from 'lodash/includes';

import type { TFavoriteSetting } from '@types/setting';

type StateType = {
  skus: Array<string>,
  series: Array<string>,
  settings: Array<TFavoriteSetting>
};

type TActionItem = {
  sku: string,
  series: string
};

type TActionData = {
  skus: Array<string>,
  series: Array<string>,
  settings: Array<TFavoriteSetting>
};

const initialState: StateType = {
  skus: [],
  series: [],
  settings: []
};

const settingIsAlreadyFavorited = (state, setting: TActionItem) => {
  return _includes(state.series, setting.series);
};

const addFavoriteSetting = (state, setting: TActionItem) => {
  return {
    ...state,
    skus: state.skus.concat(setting.sku),
    series: state.series.concat(setting.series)
  };
};

const removeFavoriteSetting = (state, setting: TActionItem): StateType => {
  const skus = state.skus;
  const series = state.series;
  const settings = state.settings;

  const newSkus = skus.filter((el) => el !== setting.sku);
  const newSeries = series.filter((el) => el !== setting.series);
  const newSettings = settings.filter((el) => el.vendor_sku !== setting.sku);

  return {
    ...state,
    skus: newSkus,
    series: newSeries,
    settings: newSettings
  };
};

export default function settingFavorites (
  state: StateType = initialState,
  action: {
    type: string,
    item?: TActionItem,
    data?: TActionData
  }
): StateType {
  switch (action.type) {
    case 'TOGGLE_FAVORITE_SETTING':
      if (!action.item) return state;

      if (settingIsAlreadyFavorited(state, action.item)) {
        return removeFavoriteSetting(state, action.item);
      } else {
        return addFavoriteSetting(state, action.item);
      }

    case 'SERVER_FAVORITED_SETTINGS_UPDATED':
      if (!action.data) return state;

      return {
        ...state,
        skus: action.data.skus,
        series: action.data.series,
        settings: action.data.settings
      };

    default:
      return state;
  }
}
