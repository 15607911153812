// @flow
type StateType = {};

const defaultState = {};

export default function editingRing (state: StateType = defaultState, action: any) {
  switch (action.type) {
    case 'UPDATE_RING_DIAMOND':
      return {...state, diamond: action.item};
    case 'UPDATE_RING_SETTING':
      return {...state, setting: action.item};
    case 'CLEAR_EDITING_RING':
      return {};
    default:
      return state;
  }
}
