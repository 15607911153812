// @flow
type StateType = {
  skipDyorDataRefresh: boolean
};

const defaultState = { skipDyorDataRefresh: false };

export default function ringSize (
  state: StateType = defaultState,
  action: any
) {
  switch (action.type) {
    default:
      return state;
  }
}
