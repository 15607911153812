import { useMemo, useState } from 'react';
import watch from 'redux-watch';

export default function useHydrated () {
  const [hydrated, setHydrated] = useState(false);
  const store = useMemo(() => ReactOnRails.getStore('SharedReduxStore'), []);

  React.useEffect(
    () => {
      const state = store.getState();
      const isRootAlreadyHydrated = state._persist.rehydrated;
      const isRingBuilderAlreadyHydrated =
        state.ringBuilder._persist.rehydrated;

      if (isRootAlreadyHydrated && isRingBuilderAlreadyHydrated) {
        setHydrated(true);
        return;
      }

      const wHydration = watch(store.getState);

      const unsubscribeHydration = store.subscribe(
        wHydration((newVal) => {
          if (
            newVal._persist.rehydrated &&
            newVal.ringBuilder._persist.rehydrated
          ) {
            setHydrated(true);
            unsubscribeHydration();
          }
        })
      );

      return unsubscribeHydration;
    },
    [store]
  );

  return hydrated;
}
