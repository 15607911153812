// @flow
import type { TRecentlyViewedSettingSummary } from '@types/setting';

type StateType = Array<TRecentlyViewedSettingSummary>;

function shortenDescription (text) {
  const sizeIndex = text.lastIndexOf('(');
  let withoutSize;

  if (sizeIndex >= 0) {
    withoutSize = text.slice(0, sizeIndex).trim();
  } else {
    withoutSize = text.trim();
  }

  let words = withoutSize.split(' ').slice(0, 2).join(' ');

  if (words.length < withoutSize.length) {
    words += '...';
  }

  return sizeIndex > 0 ? `${words} ${text.slice(sizeIndex)}` : words;
}

export default function (state: StateType = [], action: any): StateType {
  switch (action.type) {
    case 'PUSH_RECENTLY_VIEWED_SETTING':
      const summary = {
        vendor_sku: action.payload.vendor_sku,
        lumera_series: action.payload.lumera_series,
        description: shortenDescription(action.payload.short_description),
        image: action.payload.images[0].standard,
        retail_price: action.payload.retail_price,
        url_segment_description: action.payload.url_segment_description
      };

      const newSeries = summary.lumera_series;

      const existingIndexForSeries = state.findIndex(
        (setting) => setting.lumera_series === newSeries
      );

      if (existingIndexForSeries >= 0) {
        // Series is already in recents
        const recentsWithoutThisSeries = state.filter((setting) => {
          return setting.lumera_series !== newSeries;
        });

        return [summary, ...recentsWithoutThisSeries];
      } else {
        // Series is not currently listed in the recents, we need to add it.
        // We keep 4 items in this array -- the first item is the one currently
        // being viewed and the rest are your history.
        return [summary, ...state].slice(0, 4);
      }
    default:
      return state;
  }
}
