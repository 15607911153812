// @flow
import type { SettingType } from '@types/setting';

type StateType = {
  nextPage: number,
  loading: boolean
};

const defaultState = {
  nextPage: 1,
  loading: true
};

export default function settingSearch (state: StateType = defaultState, action: any) {
  switch (action.type) {
    case 'INCREMENT_NEXT_SETTINGS_PAGE':
      return {
        ...state,
        nextPage: state.nextPage + 1
      };

    case 'RESET_NEXT_SETTINGS_PAGE':
      return {
        ...state,
        nextPage: 1
      };

    case 'FETCH_SETTINGS_STARTED':
      return {
        ...state,
        loading: true
      };

    case 'FETCH_SETTINGS_SUCCESS':
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
