// @flow
import AxiosInstanceFactory from '@src/utils/axiosInstance';
import { takeEvery, all, call, put } from 'redux-saga/effects';
import * as routes from '@src/app_routes/cart';
import { handleError } from '../utils/errors';

const axiosInstance = AxiosInstanceFactory();

export default function* watchCart(): Generator<any, any, any> {
  yield all([
    takeEvery('ADD_ITEM_TO_CART', addItemToCart),
    takeEvery('CART_SYNC_REQUESTED', cartSyncRequested),
    takeEvery('SHIPPING_STATE_UPDATED', shippingStateUpdated)
  ]);
}

export function* addItemToCart(action: any): Generator<any, any, any> {
  try {
    const res = yield call(axiosInstance.post, routes.addItem, {
      item_type: action.payload.type,
      id: action.payload.id
    });

    yield put({ type: 'CART_UPDATED', cart: res.data });
    if (window) window.location = routes.cart;
  } catch (error) {
    if (window) {
      window.alert(
        'There was a problem adding the item to the cart.\n\nPlease make sure you have cookies enabled and refresh the page. If the problem continues, please try using a different browser such as Google Chrome.'
      );
    }
    yield put({ type: 'CART_UPDATE_FAILED', error: error.message });
    handleError(error);
  }
}

export function* cartSyncRequested(_action: any): Generator<any, any, any> {
  var res;

  try {
    res = yield call(axiosInstance.get, routes.apiCart);

    yield put({ type: 'CART_UPDATED', cart: res.data });
  } catch (error) {
    yield put({ type: 'CART_SYNC_FAILED', error: error.message });
    handleError(error);
  }
}

export function* shippingStateUpdated(action: any): Generator<any, any, any> {
  var res;

  try {
    res = yield call(axiosInstance.post, routes.updateShippingState, {
      state: action.shippingState
    });

    yield put({ type: 'CART_UPDATED', cart: res.data });
  } catch (error) {
    yield put({ type: 'SHIPPING_STATE_UPDATE_FAILED', error: error.message });
    handleError(error);
  }
}
