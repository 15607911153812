// @flow
import includes from 'lodash/includes';

import type { RailsContextType } from '@types/misc';

type StateType = {
  shape: Array<string>,
  price?: Array<number>,
  carat?: Array<number>,
  diamond_type: string,
};

const shapeMap = {
  Round: 'B',
  'Princess-Cut': 'PR',
  Marquise: 'M',
  'Heart-Shaped': 'H',
  Oval: 'O',
  Asscher: 'AS',
  'Pear-Shaped': 'P',
  'Emerald-Cut': 'E',
  'Cushion-Cut': 'C',
  'Radiant-Cut': 'R'
};

// IMPORTANT: If the defaults change, we need to change them in Diamond.rb
// which contains default_search_shape at the moment
const defaultState: StateType = {
  shape: ['Round'],
  price: undefined,
  carat: undefined,
  polish: undefined,
  symmetry: undefined,
  fluorescence: undefined,
  cut: undefined,
  color: undefined,
  clarity: undefined,
  depth: undefined,
  table: undefined,
  lw_ratio: undefined,
  diamond_type: 'natural',
};

export default (railsContext: RailsContextType) => {
  return function diamondSearchFilters (
    state: StateType = defaultState,
    action: any
  ) {
    switch (action.type) {
      case 'UPDATE_SHAPE':
        if (action.item && !includes(state.shape, action.item)) {
          return {
            ...state,
            shape: [action.item]
          };
        } else if (action.item && state.shape.length == 1 && action.item == state.shape[0]) {
          // if 1 item currently selected, just return with the same state,
          // i.e. do nothing. This condition is prevented by a conditional in
          // the diamond search saga, keeping the behavior here just in cases
          return {...state};
        } else {
          // if many items are selected, clicking on 1 should select it, not unselect everything
          return {
            ...state,
            shape: [action.item]
          };
        }
      case 'RESET_DIAMOND_SEARCH_SHAPES_FOR_NEW_DYOR_SETTING':
        return {
          shape: [
            'Round',
            'Pear-Shaped',
            'Emerald-Cut',
            'Marquise',
            'Oval',
            'Radiant-Cut',
            'Princess-Cut',
            'Cushion-Cut',
            'Heart-Shaped',
            'Asscher'
          ]
        };
      case 'UPDATE_PRICE':
        if (action.item) {
          return {
            ...state,
            price: action.item.sort((a, b) => parseFloat(a) - parseFloat(b))
          };
        } else {
          return {
            ...state,
            price: undefined
          };
        }
      case 'UPDATE_CARAT_WEIGHT':
        return {
          ...state,
          carat: action.item.sort((a, b) => parseFloat(a) - parseFloat(b))
        };
      case 'UPDATE_POLISH':
        return {
          ...state,
          polish: action.item
        };
      case 'UPDATE_SYMMETRY':
        return {
          ...state,
          symmetry: action.item
        };
      case 'UPDATE_FLUORESCENCE':
        return {
          ...state,
          fluorescence: action.item
        };
      case 'UPDATE_CUT':
        return {
          ...state,
          cut: action.item
        };
      case 'UPDATE_COLOR':
        return {
          ...state,
          color: action.item
        };
      case 'UPDATE_CLARITY':
        return {
          ...state,
          clarity: action.item
        };
      case 'UPDATE_DEPTH_PERCENT':
        return {
          ...state,
          depth: action.item.sort((a, b) => parseFloat(a) - parseFloat(b))
        };
      case 'UPDATE_TABLE_PERCENT':
        return {
          ...state,
          table: action.item.sort((a, b) => parseFloat(a) - parseFloat(b))
        };
      case 'UPDATE_LENGTH_WIDTH_RATIO':
        return {
          ...state,
          lw_ratio: action.item.sort((a, b) => parseFloat(a) - parseFloat(b))
        };
      case 'UPDATE_DIAMOND_SEARCH_TYPE':
        return {
          ...state,
          diamond_type: action.item,
        };
      case 'RESET_DIAMOND_FILTERS':
        return {
          ...defaultState,
          ...action.filters
        };
      case 'CLEAR_DIAMOND_SEARCH_FILTERS':
        return defaultState;
      default:
        return state;
    }
  };
};
