// @flow
import { ObjectType } from '@types/misc';

type StateType = {
  style: Array<string>,
  design: Array<string>,
  metal: Array<string>,
  price?: Array<number>,
  shape: string,
  sort: string,
  matchingBandsOnly: boolean
};

const initialState = {
  style: [],
  design: [],
  metal: [],
  price: undefined,
  shape: 'Round',
  sort: 'asc'
};

export default function settingSearchFilters (
  state: StateType = initialState,
  action: ObjectType
): ObjectType {
  switch (action.type) {
    case 'TOGGLE_SETTING_FILTER':
      const { filterName, group } = action.payload;
      const currentFiltersInGroup = [...state[group]];
      let newFilters;

      const indexOfItem = currentFiltersInGroup.indexOf(filterName);

      if (indexOfItem >= 0) {
        currentFiltersInGroup.splice(indexOfItem, 1);
        newFilters = currentFiltersInGroup;
      } else {
        newFilters = [...currentFiltersInGroup, filterName];
      }

      return {
        ...state,
        [group]: newFilters
      };

    case 'SET_SETTING_FILTER':
      return {
        ...state,
        [action.payload.filterName]: action.payload.value
      };

    case 'RESET_SETTING_FILTERS':
      return {
        ...initialState,
        ...action.filters
      };

    default:
      return state;
  }
}
