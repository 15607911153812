// @flow
import AxiosInstanceFactory from '@src/utils/axiosInstance';
import { takeLatest, select, all, call, put } from 'redux-saga/effects';
import { buildQueryString, parseQueryString } from '@src/utils/general';
import type { ObjectType } from '@types/misc';
import { handleError } from '../utils/errors';
import _cloneDeep from 'lodash/cloneDeep';
import { trackViewItemList } from '../utils/googleAnalytics';

import { encodeMetalSearchValue, decodeMetalSearchValue } from '../utils/bands';

import * as routes from '@src/app_routes/bands';
import { replaceState } from '../utils/replaceState';

import type { TBandSearchResultItem } from '@types/band';

const getBandSearchFilters = (state) => _cloneDeep(state.bandSearchFilters);
const getSearchExtentPrices = (state) =>
  _cloneDeep(state.bandSearchExtents.price);
const getNextSearchPage = (state) => state.bandSearch.nextPage;

const axiosInstance = AxiosInstanceFactory();

type BandsSearchResponseType = {
  data: {
    matches: Array<TBandSearchResultItem>
  }
};

export default function* BandsIndex (): Generator<any, any, any> {
  yield all([
    takeLatest('SYNC_URI_WITH_BAND_FILTERS', syncURIWithBandFilters),
    takeLatest('SYNC_BAND_FILTERS_FROM_URI', syncBandFiltersFromUri),
    takeLatest('FETCH_BANDS_EXTENTS', fetchBandsExtents),
    takeLatest('FETCH_BANDS', fetchBands),
    takeLatest('FETCH_INITIAL_BANDS', fetchInitialBands)
  ]);
}

export function* syncURIWithBandFilters (
  action: any
): Generator<any, any, any> {
  if (!window) return;

  const filters = yield select(getBandSearchFilters);

  const omittableKeys = ['sort'];

  if (!filters.contour) {
    delete filters.contour;
  }

  if (!filters.matchingSettingsOnly) {
    delete filters.matchingSettingsOnly;
  }

  if (filters['metal']) {
    filters['metal'] = filters['metal'].map((val) => {
      return encodeMetalSearchValue(val);
    });
  }

  omittableKeys.forEach((key) => {
    if (filters.hasOwnProperty(key)) {
      delete filters[key];
    }
  });

  const uriWithoutQueryString =
    window.location.origin + window.location.pathname;

  const queryString = buildQueryString(filters, 'bands');

  const uri =
    queryString === '' || queryString === '?'
      ? uriWithoutQueryString
      : uriWithoutQueryString + queryString;

  replaceState({}, '', uri);

  if (action.callback) action.callback();
}

export function* syncBandFiltersFromUri (
  action: ObjectType
): Generator<any, any, any> {
  try {
    if (typeof window === 'undefined') return;

    const url = window.location.href;

    const keys = [
      'style',
      't',
      'design',
      'd',
      'metal',
      'm',
      'price',
      'p',
      'gender',
      'g',
      'sort',
      'o'
    ];

    const shortenedKeys = [
      ['style', 't'],
      ['design', 'd'],
      ['metal', 'm'],
      ['price', 'p'],
      ['gender', 'g'],
      ['sort', 'o']
    ];

    const numericSortedKeys = ['price', 'p'];
    const enforcedArrayKeys = [
      'style',
      't',
      'design',
      'd',
      'metal',
      'm',
      'gender',
      'g'
    ];

    const params = parseQueryString(url.replace(/^.*?\?/, ''));
    const filters = {};

    keys.forEach((key) => {
      if (params[key]) filters[key] = params[key];
    });

    numericSortedKeys.forEach((key) => {
      if (filters[key]) {
        filters[key] = filters[key].map(Number).sort((a, b) => a - b);
      }
    });

    enforcedArrayKeys.forEach((key) => {
      if (filters.hasOwnProperty(key) && !Array.isArray(filters[key])) {
        // The value is not an array, so put it in one
        filters[key] = [filters[key]];
      }
    });

    shortenedKeys.forEach((keyPair) => {
      if (filters.hasOwnProperty(keyPair[1])) {
        filters[keyPair[0]] = filters[keyPair[1]];
        delete filters[keyPair[1]];
      }
    });

    if (filters['metal']) {
      filters['metal'] = filters['metal'].map((val) => {
        return decodeMetalSearchValue(val);
      });
    }

    if (filters['gender']) {
      filters['gender'] = filters['gender'].map((val) => {
        return val.toString().replace("'", '');
      });
    }

    // If there are no params, don't reset the params
    if (Object.keys(filters).length > 0) {
      yield put({ type: 'RESET_BAND_FILTERS', filters });
    }

    const updatedFilters = yield select(getBandSearchFilters);

    if (action.callback) action.callback(updatedFilters);
  } catch (error) {
    yield put({
      type: 'SYNC_BAND_FILTERS_FROM_URI_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* fetchInitialBands (): Generator<any, any, any> {
  try {
    yield put({ type: 'RESET_NEXT_BANDS_PAGE' });
    yield put({
      type: 'FETCH_BANDS',
      payload: { fireViewItemListEvent: true },
    });
  } catch (error) {
    yield put({
      type: 'FETCH_INITIAL_BANDS_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* fetchBandsExtents (): Generator<any, any, any> {
  try {
    yield put({ type: 'FETCH_BANDS_EXTENTS_STARTED' });

    const filters = yield select(getBandSearchFilters);

    if (!filters.contour) {
      delete filters.contour;
    }

    if (!filters.matchingSettingsOnly) {
      delete filters.matchingSettingsOnly;
    }

    const res = yield call(axiosInstance.get, routes.apiExtents(filters));

    yield put({ type: 'UPDATE_BANDS_EXTENTS', payload: res.data });
    yield put({ type: 'FETCH_BANDS_EXTENTS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'FETCH_BANDS_EXTENTS_FAILED',
      error: error.message
    });
    handleError(error);
  }
}

export function* fetchBands (action): Generator<any, any, any> {
  try {
    yield put({ type: 'FETCH_BANDS_STARTED' });

    const actionPayload = action.payload || {};
    const fireViewItemListEvent = actionPayload.fireViewItemListEvent;

    const filters = yield select(getBandSearchFilters);
    const nextPage = yield select(getNextSearchPage);
    const resetResults = nextPage === 1;

    if (resetResults) {
      yield put({
        type: 'CLEAR_BANDS',
        payload: []
      });

      yield call(fetchBandsExtents);
    }

    const updatedPriceExtents = yield select(getSearchExtentPrices);

    if (filters.price) {
      const [filterMin, filterMax] = filters.price;

      if (updatedPriceExtents[0] > filters.price[0]) {
        filters.price[0] = updatedPriceExtents[0];
      }

      if (updatedPriceExtents[0] > filters.price[1]) {
        filters.price[1] = updatedPriceExtents[0];
      }

      if (updatedPriceExtents[1] < filters.price[0]) {
        filters.price[0] = updatedPriceExtents[1];
      }

      if (updatedPriceExtents[1] < filters.price[1]) {
        filters.price[1] = updatedPriceExtents[1];
      }

      // As a last step, make sure that the newly "limited" price filter will
      // not be outside of the range the user has selected. Previously, the min
      // or max value of the extents would be used and cause the display of an
      // item outside of the user's selected price range.
      if (filters.price[0] < filterMin) {
        filters.price[0] = filterMin;
      }

      if (filters.price[1] < filterMin) {
        filters.price[1] = filterMin;
      }

      if (filters.price[0] > filterMax) {
        filters.price[0] = filterMax;
      }

      if (filters.price[1] > filterMax) {
        filters.price[1] = filterMax;
      }
    }

    if (!filters.contour) {
      delete filters.contour;
    }

    if (!filters.matchingSettingsOnly) {
      delete filters.matchingSettingsOnly;
    }

    const res = yield call(
      axiosInstance.get,
      routes.apiSearch({ page: nextPage, ...filters })
    );

    // If hasOwnProperty is not used a value of 0 is considered falsy even though that's a valid matchCount
    if (res.data.hasOwnProperty('matchCount')) {
      yield all([
        put({
          type: 'BANDS_RECEIVED',
          payload: res.data.matches
        }),
        put({
          type: 'BANDS_COUNT_RECEIVED',
          payload: res.data.matchCount
        }),
      ]);
    } else {
      yield put({
        type: 'BANDS_RECEIVED',
        payload: res.data.matches
      });
    }

    if (fireViewItemListEvent) {
      yield call(trackViewItemList, res.data.matches);
    }

    yield put({ type: 'INCREMENT_NEXT_BANDS_PAGE' });
    yield put({ type: 'FETCH_BANDS_SUCCESS' });
  } catch (error) {
    yield put({
      type: 'FETCH_BANDS_FAILED',
      error: error.message
    });
    handleError(error);
  }
}
