// @flow
import _includes from 'lodash/includes';

import type { DiamondType } from '@types/diamond';
import type { ObjectType } from '@types/misc';

type StateType = {
  ids: Array<number>,
  diamonds: Array<DiamondType>,
  sortColumn?: string,
  sortDirection?: string
};

const initialState: StateType = {
  ids: [],
  diamonds: []
};

const diamondIsAlreadyBeingCompared = (state, action) => {
  return _includes(state.ids, action.item);
};

const addDiamondForComparison = (state, action: { item: number }) => {
  return { ...state, ids: state.ids.concat(action.item) };
};

const removeDiamondFromComparison = (state, action): StateType => {
  const ids = state.ids;
  const diamonds = state.diamonds;

  const newIds = ids.filter(item => item !== action.item);
  const newDiamonds = diamonds.filter(
    item => item !== item.list_service_diamond_id
  );

  return {
    ...state,
    ids: newIds,
    diamonds: newDiamonds
  };
};

export default function diamondComparison(
  state: StateType = initialState,
  action: ObjectType
): StateType {
  switch (action.type) {
    case 'TOGGLE_DIAMOND_FOR_COMPARISON':
      if (diamondIsAlreadyBeingCompared(state, action)) {
        return removeDiamondFromComparison(state, action);
      } else {
        return addDiamondForComparison(state, action);
      }

    case 'SERVER_SAVED_DIAMONDS_UPDATED':
      return {
        ...state,
        ids: action.data.ids,
        diamonds: action.data.diamonds
      };

    case 'DIAMOND_COMPARISON_SORTED':
      return {
        ...state,
        sortColumn: action.column,
        sortDirection: action.direction
      };

    default:
      return state;
  }
}
