// @flow
import type { DiamondType } from '@types/diamond';

type StateType = {
  matches: Array<DiamondType>,
  count: number
};

const defaultState = {
  matches: [],
  count: 0
};

export default function diamonds (state: StateType = defaultState, action: any) {
  switch (action.type) {
    case 'DIAMONDS_RECEIVED_RESET':
      return {...state, matches: [...action.item]};
    case 'DIAMONDS_RECEIVED':
      return {...state, matches: [...state.matches, ...action.item]};
    case 'DIAMOND_COUNT_RECEIVED':
      return {...state, count: action.item};
    case 'CLEAR_DIAMOND_SEARCH_RESULTS':
      return defaultState;
    default:
      return state;
  }
}
