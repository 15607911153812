// @flow
import type { GetOptsType } from '@components/sliders/GenericSlider';
import type { OptionsType } from '@types/misc';

type CreateGetOptsType = (
  maxValues: ?Array<string | number> | void,
  optionsTemplateFunc: (
    values: Array<number>,
    extents: Array<number>,
    step: number,
    margin: number | void
  ) => OptionsType,
  creationOptions: {
    numericPrecision: number,
    stepSize: number,
    margin?: number | void
  }
) => GetOptsType;

export const config = {
  carat: { stepSize: 0.01, margin: 0, numericPrecision: 2 },
  depth: { stepSize: 0.1, margin: 0, numericPrecision: 1 },
  lw_ratio: { stepSize: 0.01, margin: 0, numericPrecision: 2 },
  price: { stepSize: 100, margin: 0, numericPrecision: 0 },
  table: { stepSize: 1, margin: 0, numericPrecision: 0 }
};

export const valuesWithinExtents = (
  values: Array<number>,
  extents: Array<number>,
  stepSize: number,
  margin: number | void,
  precision: number
): { extents: Array<number>, values: Array<number> } => {
  const minimumGap = margin == null ? stepSize : margin;
  const sortedExtents = extents
    .map(Number)
    .sort((a, b) => parseFloat(a) - parseFloat(b));
  if (sortedExtents[0] === sortedExtents[1])
    sortedExtents[1] = sortedExtents[1] + minimumGap;

  const sortedValues = values
    .map(Number)
    .sort((a, b) => parseFloat(a) - parseFloat(b));

  const [minValue, maxValue] = sortedExtents;
  const finalValues: Array<number> = sortedValues.map((val, idx) => {
    // Get the higher of: lower extent OR the minimum between the value and
    // the upper extent (minus the step size, so that the lower bound can't be
    // the max value). This makes sure that the chosen value can't be higher
    // than the upper extent.
    if (idx === 0)
      return Math.max(minValue, Math.min(val, maxValue - minimumGap));

    // Get the lower of: upper extent OR the maximum between the value and the
    // lower extent (plus the step size, so that the lower bound can't be
    // the max value). This makes sure that the chosen value can't be lower
    // than the lower extent.
    if (idx === 1)
      return Math.min(maxValue, Math.max(val, minValue + minimumGap));

    return val;
  });

  const preciseValues = finalValues.map((val) =>
    Number(val.toFixed(precision))
  );
  const preciseExtents = sortedExtents.map((val) =>
    Number(val.toFixed(precision))
  );

  return { extents: preciseExtents, values: preciseValues };
};

export const createGetOpts: CreateGetOptsType = (
  maxValues,
  optionsTemplateFunc,
  creationOptions = {}
) => {
  const stepSize = creationOptions.stepSize || 0.1;
  const margin = creationOptions.margin;
  const numericPrecision = creationOptions.numericPrecision;

  return (sliderValue, sliderExtent) => {
    const { values, extents } = valuesWithinExtents(
      sliderValue,
      sliderExtent,
      stepSize,
      margin,
      numericPrecision,
    );

    // prevent "min and max are the same" error
    if (extents.length === 2 && extents[1] <= extents[0]) {
      extents[1] = extents[0] + stepSize;
    }

    return optionsTemplateFunc(values, extents, stepSize, margin);
  };
};
